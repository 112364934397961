<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Login</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field @change="username=$event"
                              label="Username"
                              required
                              type="text"></v-text-field>
                <v-text-field @change="password=$event"
                              label="Password"
                              type="password"
                              required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('Close')">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  name: "LoginForm",
  mounted() {
  },
  methods: {
    save() {
      let formData = new FormData()
      formData.set("username", this.username)
      formData.set("password", this.password)
      axios.post(process.env.VUE_APP_API_URL + "/api/token/",
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          document.cookie = "token=" + response.data.access
          this.$emit('Close');
        })
        .catch(function () {  // response, validation?
            }
        )
    }
  },
  props: ["dialog"],
  data() {
    return {
      username: '',
      password: ''
    }
  }
}
</script>

<style scoped>

</style>
